<script lang="ts">
  import { onMount } from "svelte";
  //import bootstrap from "bootstrap";

  export let tooltip: string;
  export let placement: "top" | "bottom" | "left" | "right" = "top";
  export let useHtml: boolean = false;

  let element: HTMLButtonElement;
  let tooltipInstance: bootstrap.Tooltip;

  onMount(() => {
    // @ts-ignore
    tooltipInstance = new bootstrap.Tooltip(element);
  });
</script>

<div>
  <button
    class="btn"
    data-bs-toggle="tooltip"
    data-bs-placement={placement}
    data-bs-html={useHtml.toString()}
    data-bs-title={tooltip}
    bind:this={element}
    type="button"
  >
    <span> i </span>
  </button>
</div>

<style lang="scss">
  div {
    display: inline-block;
    button {
      width: 16px;
      height: 16px;
      margin: 0;
      background-color: transparent;
      color: white;
      border: 1px solid $light;
      border-radius: 1000px;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        pointer-events: none !important;
      }
    }
  }
</style>
