<script lang="ts">
  import { createEventDispatcher, tick } from "svelte";

  type Key = $$Generic<string | number>;

  interface Item {
    id: Key;
    name: string;
  }

  const dispatch = createEventDispatcher<{ select: Key | null }>();

  export let values: Item[];
  export let prefix: string = "";
  export let placeholder: string = "";
  export let chooseText: string = "Choose a tupper";
  export let buttonColor: string = "primary";
  export let id: string | undefined;
  export let selected: Key | null = null;
  export let disabled: boolean = false;

  let searchText: string = "";
  let hiLiteIndex = -1;
  let filteredResults: Item[];

  let input: HTMLElement;
  let itemList: HTMLElement;
  let dropdownButton: HTMLElement;
  let clearButton: HTMLElement;

  $: if (!searchText && values) {
    resetValues();
  } else {
    filterResults();
  }

  $: if (
    disabled &&
    dropdownButton &&
    dropdownButton.classList.contains("show")
  ) {
    //hack to close dropdown when disabled since bootstrap breaks when that happens
    dropdownButton.click();
  }

  function resetValues() {
    hiLiteIndex = -1;
    filteredResults = values;
  }
  function filterResults() {
    filteredResults = values
      .filter((item) =>
        item.name.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .sort();
  }
  async function setInputVal(item: Item) {
    selected = item.id;
    searchText = "";
    filteredResults = [];
    hiLiteIndex = -1;
    dispatch("select", item.id);
    await tick();
    clearButton.focus();
  }
  function navigateList(e: KeyboardEvent) {
    const { length } = filteredResults;
    if (length === 0) return;
    if (e.key === "Enter") {
      srSpeak(filteredResults[hiLiteIndex].name, "assertive");
      setInputVal(filteredResults[hiLiteIndex]);
      dropdownButton.click();
      e.preventDefault();
      return;
    }

    if (e.key === "ArrowUp" || (e.shiftKey && e.key === "Tab")) {
      if (hiLiteIndex === -1) hiLiteIndex = 0;
      hiLiteIndex = (hiLiteIndex - 1 + length) % length;
    } else if (e.key === "ArrowDown" || e.key === "Tab") {
      hiLiteIndex = (hiLiteIndex + 1) % length;
    } else {
      return;
    }
    itemList.children[hiLiteIndex]?.scrollIntoView({
      block: "nearest",
      inline: "start",
    });
    srSpeak(filteredResults[hiLiteIndex].name, "assertive");
    e.preventDefault();
  }
  function srSpeak(text: string, priority: string) {
    const el = document.createElement("div");
    const id = "speak-" + Date.now();
    el.setAttribute("id", id);
    el.setAttribute("aria-live", priority || "polite");
    el.classList.add("visually-hidden");
    document.body.appendChild(el);

    window.setTimeout(() => {
      el.innerHTML = text;
    }, 300);

    window.setTimeout(() => {
      document.body.removeChild(el);
    }, 3000);
  }
  function makeMatchBold(str: string) {
    const matched = str.substring(0, searchText.length);
    return str.replace(matched, `<strong>${matched}</strong>`);
  }
</script>

<div class="dropdown">
  <div class="input-group flex-nowrap">
    {#if selected}
      <button
        bind:this={clearButton}
        class={`btn btn-${buttonColor} clear-button`}
        class:disabled
        type="button"
        aria-label="Clear selected value"
        on:click={() => {
          selected = null;
          dropdownButton.focus();
          dispatch("select", null);
        }}
      >
        <i class="fas fa-times" />
      </button>
    {/if}
    <button
      class={`btn btn-dark dropdown-toggle`}
      class:disabled
      type="button"
      bind:this={dropdownButton}
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      on:click={() => {
        resetValues();
        input.focus();
      }}
    >
      {values.find((item) => item.id == selected)?.name || chooseText}
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <input
        class="form-control bg-dark text-white"
        type="text"
        autocomplete="off"
        {placeholder}
        {id}
        bind:this={input}
        bind:value={searchText}
        on:keydown={navigateList}
      />
      <div class="dropdown-items" bind:this={itemList}>
        {#each filteredResults as item, i}
          <li
            class="autocomplete-items"
            class:autocomplete-active={i === hiLiteIndex}
          >
            <button
              class="btn"
              on:click={() => setInputVal(item)}
              tabindex="-1"
            >
              {@html prefix + (prefix && " ") + makeMatchBold(item.name)}
            </button>
          </li>
        {/each}
      </div>
    </ul>
  </div>
</div>

<style lang="scss">
  .dropdown {
    max-width: 400px;
  }
  .dropdown-menu {
    width: 100%;
    padding: 0;
  }
  .dropdown-items {
    overflow-y: auto;
    max-height: 300px;
  }
  .dropdown-toggle {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $light !important;
  }
  .clear-button {
    border: 1px solid $light !important;
  }
  input {
    border: 1px solid #d4d4d4;
  }
  .autocomplete-items {
    list-style: none;
    border-bottom: 1px solid #d4d4d4;

    cursor: pointer;
    background-color: $dark;
    button {
      padding: 4px 8px;
      width: 100%;
      text-align: start;
    }
  }
  .autocomplete-items:hover {
    /*when hovering an item:*/
    background-color: $primary;
  }
  .autocomplete-items:active {
    /*when navigating through the items using the arrow keys:*/
    background-color: darken($primary, 10%) !important;
  }
  .autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: lighten($primary, 10%) !important;
  }
</style>
